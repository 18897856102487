import classNames from "classnames";
import Gallery from "components/Portfolio/Gallery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export const Image = ({ src, alt, type, width }) => {
  return (
    <div className={classNames("image", type)}>
      <img src={src} alt={alt} />
    </div>
  );
};

export const Grids = ({
  images,
  layout,
  spacing,
  rowSize,
  colSize,
  padding,
}) => {
  const photos = images.map((img, idx) => ({
    src: img.thumbnail_url,
    ...img,
  }));

  return (
    <div style={{ paddingLeft: `${padding}%`, paddingRight: `${padding}%` }}>
      <Gallery
        galleryProps={{
          layout: layout,
          targetRowHeight: Number(rowSize) * 100,
          columns: (containerWidth) => {
            if (containerWidth < 575.98) return 1;
            if (containerWidth < 767.98) return 2;
            return colSize;
          },
          spacing: spacing,
        }}
        photos={photos}
        lightBox={false}
      />{" "}
    </div>
  );
};

export const ImageSlider = ({ images }) => {
  return (
    <div className="image-slider">
      <div className="fullscreen">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {images.map((item, index) => (
            <SwiperSlide key={index}>
              <img className="fade-in-image" src={item.src} alt={item.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
