import likes from "assets/images/icons/linkedin-likes.webp";
import React, { useState } from "react";
import { Card, CardBody, CardText, CardImg, Button } from "reactstrap";
import "./LinkedInPostPreview.scss";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import { SuccessPopup } from "components/Notification/GeneralPopups";
const LinkedInPostPreview = ({
  id,
  size,
  imageSrc,
  caption,
  handleOnOpenEditCaption,
  linkedinProfileData,
  handlePost,
  isPosted,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleOnPost = async () => {
    setIsSubmitting(true);
    try {
      const response = await handlePost({ id, caption, image_url: imageSrc });
      if (response.ok) {
        // toast.success("Posted successfully");
        swal({
          content: (
            <SuccessPopup heading={"Poster Poster Successfully"} popper={true}>
              <div>
                Congratulations you have posted the poster successfully on your
                linkedin
              </div>
            </SuccessPopup>
          ),
        });
      } else {
        toast.error("Something went wrong, please try again later");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, please try again later");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="spotlight mt-2">
      <Card className="linkedin-post-preview">
        <CardBody>
          <div className="position-absolute top-0 end-0 me-2 mt-2 badge bg-success">
            Preview
          </div>
          <div className="post-header">
            <img
              src={linkedinProfileData?.picture_url}
              alt="User Avatar"
              className="user-avatar"
            />
            <div className="user-info">
              <h5 className="user-name">{linkedinProfileData?.name}</h5>
              <p className="user-title">{linkedinProfileData?.headline}</p>
              <span className="post-time">2h ago</span>
            </div>
          </div>
          <CardText className="post-caption">{caption}</CardText>
          <CardImg top src={imageSrc} alt="Post image" className="post-image" />
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex align-items-center mt-2">
              <img src={likes} height={20} />
              <div className="comments-count">1,830</div>
            </div>

            <div className="comments-count">100 comments</div>
          </div>

          {size === "big" && (
            <>
              <div className="divider mt-2 p-0" />
              <div className="post-actions">
                <Button color="link">
                  <div>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/windows/32/thumb-up.png"
                      alt="thumb-up"
                    />
                    <div>Like</div>
                  </div>
                </Button>
                <Button color="link">
                  <div>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/fluency-systems-regular/50/comments--v1.png"
                      alt="comments--v1"
                    />
                    <div>Comment</div>
                  </div>
                </Button>
                <Button color="link">
                  <div>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/pulsar-line/48/retweet.png"
                      alt="retweet"
                    />
                    <div>Repost</div>
                  </div>
                </Button>
                <Button color="link">
                  <div>
                    <img
                      width="23"
                      height="23"
                      src="https://img.icons8.com/sf-regular/48/sent.png"
                      alt="sent"
                    />
                    <div>Send</div>
                  </div>
                </Button>
              </div>
            </>
          )}
        </CardBody>
      </Card>

      {size === "big" && (
        <div className="mx-2 d-flex gap-2">
          <button
            disabled={isSubmitting || isPosted}
            onClick={handleOnOpenEditCaption}
            className="btn btn-rounded-2 btn-gallery-outline-primary w-100 py-1 mt-2"
          >
            <i className="mdi mdi-pencil me-2 align-middle fs-5 " />
            Customise Poster
          </button>
          {isPosted ? (
            <button
              className="w-100 btn btn-success btn-rounded-2 mt-2"
              disabled={true}
            >
              <i className="mdi mdi-check-circle me-1" />
              Posted Successfully
            </button>
          ) : (
            <Button
              disabled={isSubmitting}
              onClick={handleOnPost}
              className="btn-rounded-2 linkedin-post-btn mt-2"
            >
              {isSubmitting ? (
                <i className="mdi mdi-loading mdi-spin me-2 align-middle" />
              ) : (
                <i className="mdi mdi-linkedin me-2 align-middle fs-5 " />
              )}
              Post On LinkedIn
            </Button>
          )}

          {/* <div>Reach upto 10,000 people in your network</div> */}
        </div>
      )}
    </div>
  );
};
LinkedInPostPreview.props = {
  size: "big",
};
export default LinkedInPostPreview;
