import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { getPublicGalleryEventList } from "helpers/jiraya_helper";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";
import { GET_PUBLIC_EVENT_GALLERY } from "helpers/jiraya_url";
import { EVENT_ORDER_BY } from "../../components/Events/enums";
import PublicEventCard from "components/Public_gallery/PublicEventCard";
import PublicGalleryFooter from "components/Public_gallery/PublicgalleryFooter";
import PublicEventForm from "components/Events/Forms/publicEventForm";
import PublicGalleryNavbar from "components/Public_gallery/PublicgalleryNavbar";
import { GET_PUBLIC_EVENT_BRANDING } from "helpers/jiraya_url";
import { getPublicGalleryEventBranding } from "helpers/jiraya_helper";
import EventFilter from "components/Public_gallery/EventFilter";
import GalleryPage from "./GalleryPage2";
import eventBgImage from "assets/images/portfolio/events-bg.webp";
import { SocialNavMonochrome } from "components/Events/Client/Branding/SocialNav";
import classNames from "classnames";
import { SocialNavColor } from "components/Events/Client/Branding/SocialNav";

const ENV = process.env.REACT_APP_ENV;
const NoEvents = () => {
  return (
    <div className="ht-70 d-flex flex-column align-items-center justify-content-center">
      <div className="mt-4">
        {/* <img className="img-fluid" width={"500px"} src={eventImage} /> */}
        <lottie-player
          src="https://assets6.lottiefiles.com/packages/lf20_lnn3yenp.json"
          background="transparent"
          speed="1"
          style={{ width: "auto", maxHeight: "250px" }}
          className="animation"
          rendererSettings={{ viewBoxSize: "700 0 2400 590" }}
          loop
          autoplay
        ></lottie-player>
      </div>
      <h4 className="mt-4">No Events Available</h4>
    </div>
  );
};

const PAGE_SIZE = 18;
const Page = ({ pageData, gridStyle }) => {
  if (pageData.data.length > 0) {
    return (
      <Row>
        {pageData.data.map((item, index) => (
          <Col md={12}>
            <PublicEventCard
              event={item}
              event_access_key={item.event_access_key}
              event_ask_pin={item.event_ask_pin}
              key={item.event_id}
              gridStyle={gridStyle}
            />
          </Col>
        ))}
      </Row>
    );
  } else {
    return <NoEvents />;
  }
};
const PageSmall = ({ pageData, gridStyle }) => {
  if (pageData.data.length > 0) {
    return (
      <Row className="gx-0">
        {pageData.data.map((item, index) => (
          <Col md={4} key={index} className="gy-4 gx-4">
            <PublicEventCard
              event={item}
              access_key={item.event_access_key}
              event_ask_pin={item.event_ask_pin}
              event_access_level={item.event_access_level}
              key={item.event_id}
              gridStyle={gridStyle}
            />
          </Col>
        ))}
      </Row>
    );
  } else {
    return <NoEvents />;
  }
};

// console.log(url);

const PublicGallery = () => {
  const { host, pathname } = new URL(window.location);
  const [domainUrl, setDomainUrl] = useState("");

  useEffect(() => {
    let _domainUrl;
    if (host.includes("site.fotoowl.ai")) {
      _domainUrl = host + pathname;
    } else if (host.includes("localhost")) {
      _domainUrl =
        ENV === "dev"
          ? `dev-site.fotoowl.ai` + pathname
          : "site.fotoowl.ai" + pathname;
    } else {
      _domainUrl = host;
    }
    setDomainUrl(_domainUrl);
  }, [pathname]);

  const [orderBy, setOrderBy] = useState(EVENT_ORDER_BY.EVENT_TIME_DESC);
  const [searchQuery, setSearchQuery] = useState();
  const [eventSearchQuery, setEventSearchQuery] = useState("");
  const [branding, setBranding] = useState(false);
  //   useDocumentTitle("Foto Owl - My Events");
  const getKey = (pageIndex, previousPageData) => {
    if (!domainUrl) {
      return null;
    }
    if (
      previousPageData &&
      (!previousPageData.data || !previousPageData.data.length)
    ) {
      return null;
    } // reached the end
    return [
      GET_PUBLIC_EVENT_GALLERY,
      pageIndex,
      eventSearchQuery,
      orderBy,
      domainUrl,
    ]; // SWR key
  };
  const { data, size, setSize, error } = useSWRInfinite(
    getKey,
    (url, page, search_text, sort_type, domain_url) => {
      console.log(`page: ${page}`);
      return getPublicGalleryEventList({
        params: {
          domain_url,
          search_text,
          sort_type,
          page,
          page_size: PAGE_SIZE,
        },
      });
    }
  );

  const { data: brandingData, error: brandError } = useSWR(
    domainUrl ? [GET_PUBLIC_EVENT_BRANDING] : null,
    (url) => {
      return getPublicGalleryEventBranding({
        params: {
          domain_url: domainUrl,
        },
      });
    }
  );

  const [lastPage, setLastPage] = useState();
  const currentPage = size - 1;
  const pageData = data && data[currentPage];
  const brandData = brandingData?.data;
  const loading = !pageData;
  const isLastPage =
    !loading && pageData?.data && pageData.data.length < PAGE_SIZE;
  const noEvents = pageData && !pageData.data && currentPage === 0;

  var errorMessage = !loading && error;
  const updatePage = (i) => {
    setSize(size + i);
  };

  const gotoPage = (pageNum) => {
    console.log(`go to page ${pageNum}`);
    setSize(pageNum);
  };

  useEffect(() => {
    if (pageData && !pageData.data && currentPage === 0) {
      setLastPage(-1);
    }
  }, [pageData]);

  useEffect(() => {
    const pageData = data && data[currentPage];
    if (pageData && !pageData.data && pageData.data.length < PAGE_SIZE) {
      setLastPage(currentPage);
    }
  }, [data]);

  const [gridStyle, setGridStyle] = useState("small");
  const changeGridStyle = (s) => {
    localStorage.setItem("gridStyle", s);
    setGridStyle(s);
  };
  useEffect(() => {
    const s = localStorage.getItem("gridStyle");
    if (s) {
      setGridStyle(s);
    }
  }, []);
  const eventList = useCallback(() => {
    if (loading) {
      return (
        <div className="loader loader-full">
          <Loader />
        </div>
      );
    } else if (pageData && pageData.data.length === 0 && currentPage === 0) {
      return <NoEvents />;
    } else if (errorMessage) {
      return <div className="loader loader-full">{errorMessage}</div>;
    } else if (!pageData.data || pageData.data.length == 0) {
      // rollback to previous page
      updatePage(-1);
      // set the last page
      setLastPage(currentPage - 1);
    } else {
      if (gridStyle === "small")
        return <PageSmall pageData={pageData} gridStyle={gridStyle} />;
      else return <Page pageData={pageData} gridStyle={gridStyle} />;
    }
  }, [pageData, loading, gridStyle]);

  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);

  // Event search funtionality chnages.

  const handleOnOrderBy = (v) => {
    console.log(v);

    setOrderBy(v); // reloadImages();
  };
  if (host.includes("kittumangayi")) {
    return <GalleryPage eventId={4060} />;
  }

  return (
    <React.Fragment>
      <div className="public-event">
        {/* <PublicGalleryNavbar
          branding={brandData}
          eventId={pageData?.event_id}
        /> */}

        <Container fluid className="flex-grow-1">
          <div className="page-cont">
            <div className="public-event-cover-container">
              <div className="public-event-cover-container-inner">
                <div className="public-event-cover-overlay" />
                <img src={eventBgImage} className="public-event-cover-img" />
                {/* <div className="public-event-cover-text">
                  {brandData?.img_url && (
                    <img
                      className=" public-event-cover-brand-logo"
                      src={brandData?.img_url}
                    />
                  )}
                  {brandData?.name && (
                    <h1 className="mt-2">{brandData?.name}</h1>
                  )}
                  <div className="public-event-cover-sm">
                    {brandData && (
                      <SocialNavMonochrome brandingData={brandData} />
                    )}
                  </div>
                </div> */}
              </div>
            </div>
            <div className="public-event-list-container">
              <div className="public-event-cover-text">
                <div>
                  {brandData?.img_url && (
                    <img
                      className=" public-event-cover-brand-logo"
                      src={brandData?.img_url}
                    />
                  )}
                </div>
                {brandData?.name && <h1 className="mt-2">{brandData?.name}</h1>}
                <div className="public-event-cover-sm">
                  {brandData && <SocialNavColor brandingData={brandData} />}
                </div>
              </div>
              <div className="position-relative public-event-list-container-inner">
                <Row className="px-3 gx-0">
                  <div className="public-event-toolbar">
                    <EventFilter
                      toggle={toggle}
                      open={open}
                      setSearchQuery={setSearchQuery}
                      setEventSearchQuery={setEventSearchQuery}
                      handleSort={handleOnOrderBy}
                      searchQuery={searchQuery}
                      eventId={pageData?.event_id}
                    />
                  </div>
                </Row>
                <Row className="d-flex justify-content-center pt-0 gx-0 mt-2">
                  <Col md={11} xl={9}>
                    {eventList()}
                  </Col>
                </Row>
              </div>
            </div>

            {!noEvents && (
              <div className="mt-5 d-flex justify-content-end align-items-center mb-2 me-2 gap-3">
                <ul className="pagination pagination-sm">
                  <li
                    className={classNames("page-item ", {
                      disabled: currentPage === 0,
                    })}
                    disabled={currentPage === 0}
                  >
                    <a
                      onClick={() => updatePage(-1)}
                      className="page-link"
                      href="#"
                      tabIndex="-1"
                    >
                      Previous
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      {currentPage + 1}
                    </a>
                  </li>

                  <li
                    className={classNames("page-item", {
                      disabled: isLastPage,
                    })}
                    disabled={isLastPage}
                  >
                    <a
                      onClick={() => updatePage(1)}
                      className="page-link"
                      href="#"
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Container>
        <footer>
          <PublicGalleryFooter branding={brandData} />
        </footer>
      </div>
    </React.Fragment>
  );
};
export default PublicGallery;
