import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  Modal,
  Badge,
  Input,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvInput,
  AvCheckbox,
  AvCheckboxGroup,
  AvGroup,
} from "availity-reactstrap-validation";
import LiveSelfie from "components/CaptureSelfie/LiveSelfie_iphone";
import Selfie from "components/CaptureSelfie/Selfie";
import AntiSpoofSelfie from "components/CaptureSelfie/LiveAntiSpoofSelfie";

// import FaceDetectSelfie from "components/CaptureSelfie/FaceDetectSelfie";
import {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { postRequest } from "helpers/jiraya_helper";
import { usePost } from "services/usePost";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import useSWR from "swr";
import { getOpenUserLocation } from "helpers/jiraya_helper";
import { GET_OPEN_USER_LOCATION } from "helpers/jiraya_url";
import classNames from "classnames";
import {
  getEventCustomisations,
  registrationPrivacyLinks,
} from "./customisation";
import getField, { useAdditionalFields } from "./form_fields";
import { useTranslation, Trans } from "react-i18next";
import getAdditionalFields from "./form_customisation";
import SanyForm from "./sany_machines_form";
const InputWrapper = forwardRef((props, ref) => {
  return (
    <AvField
      {...props}
      ref={ref}
      name="mobileNumber"
      placeholder="Mobile Number"
      // type="number"
      errorMessage="Enter Mobile Number"
      className="form-control w-100"
      validate={{ required: { value: props.required_mobile } }}
    />
  );
});

const FaceSearchFormFields = ({
  eventId,
  eventData,
  isPreRegistration,
  mobileNumber,
  onMobilInputChange,
  errorMessages,
  isVIPLink,
  registrationForm,
  additionalFieldsProps,
  additionalFields,
}) => {
  const fields = [];

  const { data: countryData } = useSWR(
    GET_OPEN_USER_LOCATION,
    getOpenUserLocation
  );
  const { t } = useTranslation();

  if (eventData?.facesearch_form.get_guest_name && !isVIPLink) {
    const required_name = eventData.facesearch_form.get_guest_name_required;
    fields.push(
      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="name">
              {t("Name")} {required_name && "*"}
            </Label>
            <AvField
              name="name"
              placeholder={t("Enter Your Name")}
              type="text"
              errorMessage={t("Enter Your Name")}
              className="form-control face-search-field"
              validate={{ required: { value: required_name } }}
              id="name"
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
  if (
    registrationForm.email &&
    eventData?.facesearch_form.get_guest_email_id &&
    !isVIPLink
  ) {
    const required_email_id =
      eventData.facesearch_form.get_guest_email_id_required;
    fields.push(
      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="emailId">
              {t("Email Id")} {required_email_id && "*"}
            </Label>
            <AvField
              name="emailId"
              placeholder="johnwick@gmail.com"
              type="email"
              errorMessage={t("Enter Email ID")}
              className="form-control face-search-field"
              validate={{ required: { value: required_email_id } }}
              id="emailId"
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
  if (eventData?.facesearch_form.get_guest_mobile_number && !isVIPLink) {
    const required_mobile = eventData?.facesearch_form
      .get_guest_mobile_number_required
      ? true
      : false;

    fields.push(
      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="emailId">
              {t("Mobile Number")}
              {required_mobile && " *"}
            </Label>
            {/*  <AvField
              name="mobileNumber"
              placeholder="Mobile Number"
              type="number"
              errorMessage="Enter Mobile Number"
              className="form-control"
              validate={{ required: { value: required_mobile } }}
              id="mobileNumber"
              // maxLength="10"
            /> */}
            <PhoneInput
              placeholder={t("Enter Mobile Number")}
              value={mobileNumber}
              // onCountryChange={handleOnCountryChange}
              onChange={onMobilInputChange}
              defaultCountry={countryData?.data?.country}
              numberInputProps={{
                className: classNames("form-control face-search-field", {
                  "is-invalid": errorMessages.mobileNumber,
                }),
              }}
            />
            {errorMessages.mobileNumber && (
              <div className="d-block invalid-feedback">
                {t(errorMessages.mobileNumber)}
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
    );
  }

  if (additionalFields.length > 0) {
    additionalFields.forEach((field) => {
      const { type, ...fieldProps } = field;
      const Field = getField(type);
      fields.push(
        <Field
          {...{ ...fieldProps, ...additionalFieldsProps }}
          className="face-search-field"
        />
      );
    });
  }
  return (
    <div>
      {fields.map((item) => item)}
      {Number(eventId) === 65782 && <SanyForm {...additionalFieldsProps} />}
    </div>
  );
};

const FaceSearchForm = ({
  passkey,
  accessKey,
  eventId,
  submitButton,
  onResponse,
  isPreRegistration,
  eventData,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
  errors,
}) => {
  const { t } = useTranslation();
  let additionalFields = getAdditionalFields(eventId, eventData);

  const [selfieCapture, setSelfieCapture] = useState(false);
  const additionalFieldsProps = useAdditionalFields();
  const history = useHistory();
  const [formRef, setFormRef] = useState();
  const [errorMessages, setErrorMessages] = useState({});

  const [mobileNumber, setMobileNumber] = useState();
  const onMobilInputChange = (val) => {
    setMobileNumber(val);
    setErrorMessages({ ...errorMessages, mobileNumber: null });
  };

  const [selfie, setSelfie] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const toggleCamera = () => {
    setOpenCamera(!openCamera);
  };
  const closeCamera = () => {
    setOpenCamera(false);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
  });

  //VIP link
  const queryParams = new URLSearchParams(window.location.search);
  const isVIPLink = Number(queryParams.get("vip-link"));

  const handleOnDiscardSelfie = () => {
    setSelfie(null);
    setSelfieCapture(false);
  };
  const onCaptureDone = (verified, frames) => {
    closeCamera();
    if (verified) {
      // setAlert({
      //   type: "success",
      //   message: "Face Captured Successfully",
      //   title: "Good Job",
      //   show: true,
      // });
      setSelfieCapture(true);
      setSelfie(frames[0]);
    }
    //  else {
    //   setAlert({
    //     type: "error",
    //     message: "Failed to Capture Selfie",
    //     title: "Retake Selfie",
    //     show: true,
    //   });
    // }
  };

  const Alert = (
    <SweetAlert
      title={alert.title}
      success={alert.type === "success"}
      error={alert.type === "error"}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={() => {
        setAlert({ ...alert, show: false });
      }}
    >
      {alert.message}
    </SweetAlert>
  );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const [selfieError, setSelfieError] = useState("");
  const clearForm = () => {
    // clear form
    formRef.reset();
    setSelfieCapture(false);
    setSelfie();
    setSelfieError("");
    setMobileNumber();
    setErrorMessages({});
    additionalFieldsProps.clear();
  };

  const handleValidation = (
    additionalFieldsProps,
    selfieCapture,
    required_mobile
  ) => {
    var _errors = null;
    if (!additionalFieldsProps.validate()) {
      _errors = true;
    }
    if (!selfieCapture) {
      setSelfieError("Please Capture Selfie to Register");
      _errors = true;
    }
    if (required_mobile && !mobileNumber) {
      setErrorMessages({ mobileNumber: "Enter Mobile Number" });
      _errors = true;
    }
    return _errors;
  };
  const handleOnSubmit = async (event, values, selfie) => {
    const required_mobile =
      eventData?.facesearch_form.get_guest_mobile_number &&
      eventData?.facesearch_form.get_guest_mobile_number_required &&
      !isVIPLink;
    const _errors = handleValidation(
      additionalFieldsProps,
      selfieCapture,
      required_mobile
    );

    if (!_errors) {
      const _emailId = values.emailId;
      const fileName = _emailId
        ? `${eventId}_${values.emailId.split("@")[0]}.webp`
        : `${eventId}_temp-search.webp`;
      setIsSubmitting(true);
      var formData = new FormData();
      const file = dataURLtoFile(selfie, fileName);
      // const file2 = document.getElementById("file").files[0];
      let registeredData = {};
      _emailId && localStorage.setItem("client-login", values.emailId);
      if (values.name) {
        registeredData.name = values.name;
        formData.append("name", values.name);
      }
      if (mobileNumber) {
        formData.append("mobile_number", mobileNumber);
        registeredData.mobile_number = mobileNumber;
      }
      formData.append("file", file);
      formData.append("event_id", eventId);
      formData.append("requester_email_id", values.emailId);
      formData.append(
        "opt_in_for_marketing",
        values.opt_in_for_marketing || false
      );

      formData.append("key", passkey);
      accessKey && formData.append("access_key", accessKey);
      formData.append("form_dict_str", additionalFieldsProps.toFormData());

      const setError = () =>
        setAlert({
          type: "error",
          message: "failed to request, please contact provider",
          title: "Failed",
          show: true,
        });
      try {
        const response = await postRequest(formData);
        localStorage.setItem(
          "registration-data",
          JSON.stringify(registeredData)
        );
        onResponse(response);
        setIsSubmitting(false);
        clearForm();
        additionalFieldsProps.clear();
      } catch (err) {
        console.log(err);
        setError();
        setIsSubmitting(false);
        // clearForm();
        // additionalFieldsProps.clear();
      }
    }
  };

  const [consent, setConsent] = useState(false);
  const getIsSubmittingAnimation = useCallback(() => {
    if (isPreRegistration) {
      return (
        <div className="d-flex flex-column align-items-center">
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_2jczmi5y.json"
            background="transparent"
            speed="1"
            style={{ width: "auto", height: "300px" }}
            className="animation"
            rendererSettings={{ viewBoxSize: "500 0 2400 590" }}
            loop
            autoplay
          ></lottie-player>
          <h4>{t("Registering you in the Event")}</h4>
          <div>
            {t(
              "You will also receive an Email or WhatsApp with a link to your photos, when the host uploads and publish the event"
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column align-items-center">
          <lottie-player
            src="https://assets8.lottiefiles.com/private_files/lf30_jo7huq2d.json"
            background="transparent"
            speed="1"
            style={{ width: "auto", height: "300px" }}
            className="animation"
            rendererSettings={{ viewBoxSize: "500 0 2400 590" }}
            loop
            autoplay
          ></lottie-player>
          <h4>{t("Searching your Photos")}</h4>
          <div>
            {t(
              "You will also receive an Email / WhatsApp with a link to your photos"
            )}
          </div>
        </div>
      );
    }
  }, [isPreRegistration]);

  const {
    consentPolicy,
    promotionalContactConsent,
    basicConsent,
    registrationForm,
    showCameraHelpVideo,
  } = useMemo(
    () => getEventCustomisations(eventId, eventData.creator_user_id),
    [eventId, eventData]
  );
  return (
    <div className="face-search-form">
      {!isSubmitting ? (
        <AvForm
          className="needs-validation"
          onValidSubmit={(event, values) =>
            handleOnSubmit(event, values, selfie)
          }
          onSubmit={(event, values) => {
            if (!selfieCapture) {
              setSelfieError("Please Capture Selfie to Register");
            }
            additionalFieldsProps.validate();
          }}
          ref={(r) => setFormRef(r)}
          model={{ opt_in_for_marketing: true }}
        >
          {/* <Row>
          <Col>
            <AvInput name="file" type="file" id="file"></AvInput>
          </Col>
        </Row> */}

          <FaceSearchFormFields
            eventId={eventId}
            eventData={eventData}
            isPreRegistration={isPreRegistration}
            mobileNumber={mobileNumber}
            onMobilInputChange={onMobilInputChange}
            errorMessages={errorMessages}
            isVIPLink={isVIPLink}
            registrationForm={registrationForm}
            additionalFieldsProps={additionalFieldsProps}
            additionalFields={additionalFields}
          />

          <Row className="mb-2">
            <Label htmlFor="emailId">{t("Capture Selfie")} *</Label>
            <Col className="d-flex justify-content-start">
              {!selfieCapture ? (
                <button
                  className="btn btn-gallery-soft-primary w-100 text-start"
                  onClick={toggleCamera}
                  type="button"
                >
                  <span className="me-2">
                    <i className="mdi mdi-camera"></i>
                  </span>
                  {t("Open Camera")}
                </button>
              ) : (
                <div className=" w-100">
                  <div
                    className="d-flex  align-items-center justify-content-between badge-soft-success rounded-pill px-2 mr-4 w-100"
                    //   style={{paddingLeft:"10px"}}
                    //   onClick={toggleCamera}
                  >
                    <div>
                      <i className="mdi mdi-face-recognition mx-2"></i>
                      {t("Selfie Captured")}
                    </div>

                    <button
                      className="btn"
                      style={{ color: "red" }}
                      onClick={handleOnDiscardSelfie}
                      type="button"
                    >
                      <i className="mdi mdi-window-close"></i>
                    </button>
                  </div>
                </div>
              )}
            </Col>
            {selfieError !== "" && !selfieCapture && (
              <div className="d-block invalid-feedback">{selfieError}</div>
            )}
          </Row>
          <div className="mb-2 mt-4">
            {basicConsent && (
              <Col md={12}>
                <AvCheckboxGroup
                  name="consent"
                  validate={{
                    required: {
                      value: true,
                    },
                  }}
                  errorMessage={t("Accept the privacy policy to continue")}
                  className="ms-1 d-flex flex-column align-items-center gap-2 custom-check-form-group registration-tnc-check-box"
                >
                  <AvCheckbox />
                  <div className="consent-label">
                    <Trans
                      i18nKey="privacy policy"
                      components={{
                        privacy: (
                          <a href={consentPolicy.privacy} target="_blank">
                            Privacy Policy
                          </a>
                        ),
                        consent: (
                          <a href={consentPolicy.consent} target="_blank">
                            Consent
                          </a>
                        ),
                      }}
                    ></Trans>
                  </div>
                </AvCheckboxGroup>
              </Col>
            )}
          </div>
          <Row className="mb-2 mt-4">
            {promotionalContactConsent && !isVIPLink && (
              <Col md={12}>
                <div className="d-flex gap-2  registration-tnc-check-box">
                  <AvInput type="checkbox" name="opt_in_for_marketing" />
                  <div className="consent-label">
                    {t(
                      "I would like to receive future promotions, offers and communications from the organisers of the event."
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>

          <div className="d-flex gap-2 justify-content-center w-100 mt-4">
            <button
              disabled={isSubmitting}
              className="btn btn-gallery-primary px-5 w-100"
              type="submit"
            >
              {isSubmitting && (
                <i className="mdi mdi-loading mdi-spin font-size-16 align-middle me-2"></i>
              )}
              {submitButton}
            </button>
          </div>
          {isGuestUploadAllowed && (
            <button
              disabled={isSubmitting}
              className="btn btn-gallery-outline-primary px-5 w-100 mt-2"
              type="button"
              onClick={handleOpenGuestUpload}
            >
              Upload My POV Photos
            </button>
          )}
        </AvForm>
      ) : (
        getIsSubmittingAnimation()
      )}

      <Modal
        size="md"
        isOpen={openCamera}
        toggle={toggleCamera}
        className="capture-selfie gallery-modal"
        centered
      >
        <div
          style={{ height: "540px", width: "380px" }}
          className="camera-selfie"
        >
          {eventData?.selfie_validation_type === 2 ? (
            <LiveSelfie
              onDone={onCaptureDone}
              onClose={toggleCamera}
              showCameraHelpVideo={showCameraHelpVideo}
            />
          ) : (
            <Selfie
              onDone={onCaptureDone}
              onClose={toggleCamera}
              showCameraHelpVideo={showCameraHelpVideo}
            />
          )}
        </div>
      </Modal>

      {alert.show && Alert}
    </div>
  );
};

const SubmitButton = () => {
  const { t } = useTranslation();
  return (
    <>
      <i className="mdi mdi-image-search fs-4 align-middle me-2" />
      {t("Get My Photos")}
    </>
  );
};
FaceSearchForm.defaultProps = {
  isPreRegistration: false,
  submitButton: <SubmitButton />,
};
export default FaceSearchForm;
