export const POST_EVENT = "/admin/event";
export const GET_EVENT = "/admin/event";
export const GET_EVENT_LIST = "/admin/event-list";
export const GET_EVENT_IMAGES = "/admin/event/picture/list";
export const DELETE_EVENT = "/admin/event";
export const PATCH_EVENT_COVER_IMAGE = "/admin/event/cover-image";
export const DOWNLOAD_IMAGES = "/admin/event/pictures/download";

export const GET_COLLECTION_LIST = "/admin/event/collection-list";
export const POST_COLLECTION = "/admin/event/collection";
export const PATCH_APPEND_COLLECTION = "/admin/event/collection/append";
export const PATCH_REMOVE_COLLECTION = "/admin/event/collection/remove";

export const PATCH_COLLABORATOR = "/admin/event/collaboration";
export const GET_COLLABORATOR = "/admin/event/collaboration";
export const DELETE_COLLABORATOR = "/admin/event/collaboration";

export const UPDATE_EVENT_COMMERCIAL = "/admin/event/commercial";
export const UPDATE_EVENT_COMMAN = "/admin/event";

export const POST_TRIGGER = "/admin/event/trigger";
export const GET_TRIGGER_STATUS = "/admin/event/trigger";

export const GET_LOW_IMAGE = "/admin/event/picture/low";
export const POST_IMAGE = "/admin/event/picture";
export const DELETE_IMAGE = "/admin/event/picture";
export const GET_UPLOAD_URL = "/admin/event/upload-url";
export const POST_UPLOAD_DONE = "/admin/event/picture/process";
export const POST_UPLOAD_ALL_DONE = "/admin/process/started";

// portfolio
export const GET_PORTFOLIO = "/users/portfolio";
export const PATCH_COLLECTION_TO_PORTFOLIO = "/users/portfolio/collection";

//portfolio Gallery
export const GET_PORTFOLIO_EVENT = "/open/event/portfolio_page";

// design
export const PATCH_DESIGN_SETTING = "/admin/event/design-setting";

// drive
export const GET_DRIVE_STATUS = "/drive/status";
export const UPDATE_DRIVE_CRED = "/drive/cred";
export const GET_DRIVE_FOLDER_LIST = "/drive/folder_list";
export const SELECT_DRIVE_FOLDER_FOR_EVENT = "/drive/event/folder";
export const GET_DRIVE_UPLOAD_PROGRESS = "admin/event/upload-progress";

export const GET_SELFIE = "/users/selfie";
export const PATCH_SELFIE = "/users/selfie";
export const GET_USER = "/users";
export const PATCH_USER = "/users";

export const GET_PASS_KEY = "/admin/event/pass-key";
export const PATCH_PASS_KEY = "/admin/event/pass-key";

export const GET_STORAGE = "/users/storage";

// branding
export const POST_BRANDING = "/users/branding";
export const PATCH_BRANDING = "/users/branding-update";
export const DELETE_BRANDING = "/users/branding";
export const GET_LIST_BRANDING = "/users/branding/list";
export const GET_BRANDING = "/open/branding";

// domain
export const GET_LIST_DOMAIN = "/users/domain";
export const GET_DOMAIN = "/users/domain";
export const PATCH_DOMAIN = "/users/domain";
export const DELETE_DOMAIN = "/users/domain";
export const POST_DOMAIN = "/users/domain";
export const PATCH_SUBDOMAIN = "/users/sub-domain";
export const PATCH_VERIFY_DOMAIN = "/users/domain/verify";

// subcribe
export const POST_CHECKOUT = "/admin/checkout";

// open urls
export const GET_REQUEST_IMAGES = "";
export const POST_REQUEST = "/open/request";
export const SEARCH_EVENT = "/open/event/search";
export const GET_EVENT_OPEN = "/open/event";
export const GET_EVENT_IMAGES_OPEN = "/open/event/image-list";
export const GET_COLLECTION_LIST_OPEN = "/open/event/collection-list";
export const GET_PIN_CHECK = "/open/event/validate-key";
export const GET_OPEN_PORTFOLIO = "/open/portfolio";
export const GET_OPEN_PORTFOLIO_EVENT_IMAGES = "/open/portfolio/event/image";
export const GET_OPEN_DOWNLOAD_IMAGE = "/open/image/download";
export const GET_OPEN_START_DOWNLOAD_JOB = "/open/download";
export const GET_OPEN_DOWNLOAD_JOB_STATUS = "/open/download/status";
export const GET_OPEN_IMAGE_LIKES = "/open/image/like";
export const GET_OPEN_IMAGE_COMMENTS = "/open/image/comment";
export const POST_OPEN_IMAGE_COMMENT = "/open/image/comment";
export const DELETE_OPEN_IMAGE_LIKES = "/open/image/like";
export const POST_OPEN_IMAGE_LIKES = "/open/image/like";
export const GET_OPEN_DOWNLOAD_LINKS = "/open/download/mail-link";
export const GET_OPEN_COMMENT_BY_ID = "/open/image/comment-by-id";
export const POST_OPEN_INQUIRY = "/open/inquiry";
export const POST_OPEN_ACTIVITY = "/open/activity";
export const POST_OPEN_DOWNLOAD_IMAGE_WITH_WM = "/open/download_image";
export const GET_OPEN_USER_LOCATION = "/open/user-location";
export const GET_OPEN_PEOPLE = "/open/event/image-face-info";

//public gallery
export const GET_PUBLIC_EVENT_GALLERY = "/open/access_event_list";
export const GET_PUBLIC_EVENT_BRANDING = "/open/branding/domain_url";

//sponsors
export const GET_OPEN_SPONSORS_LIST = "/open/sponsor/list";
export const GET_ORG_EVENT_LIST = "/open/org/event";

export const UPLOAD_WORKER_S3 = "/upload-worker-s3";

// advocacy posters
export const GET_LINKEDIN_SIGNIN_URL = "/open/linkedin-auth-url";
export const POST_LINKEDIN_POST = "/open/linkedin-advocacy-post";
export const POST_LINKEDIN_AUTHCODE = "/open/linkedin-auth";
export const GET_POSTERS_LIST = "/open/my-advocacy-list";
export const GET_LINKEDIN_PROFILE = "/open/linkedin";
export const PATCH_LINKEDIN_POSTER_INFO = "/open/linkedin-advocacy-info-update";

// guest user
export const PATCH_GUEST_USER_PROFILE = "/guest-user/update-firebase";
export const GET_UPLOADED_IMAGE_LIST = "/guest-user/event/image-list";
export const DELETE_GUEST_UPLOADED_IMAGE = "/guest-user/event/image";
