import { useEffect, useMemo } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import useSWR from "swr";
import { GET_EVENT } from "helpers/jiraya_url";
import { getEvent } from "helpers/jiraya_helper";
import { Image } from "components/Carousel/PublicGalleryImageStrip";
import { CFImageUrl } from "helpers/utils";

// import { gaDeleteEvent } from "tracking/events";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const PublicEventCard = ({ event, gridStyle }) => {
  const {
    event_id,
    event_ask_pin,
    name,
    description,
    cover_image_info,
    event_access_key,
    event_access_level,
    date,
    pass_key,
    share_key,
  } = event;
  let { pathname } = useLocation();

  ////////////////// event details ////////////////////////////////
  const { data: eventData, mutate } = useSWR(
    [GET_EVENT, event_id],
    () => getEvent({ params: { event_id: event_id } }),
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
    }
  );
  useEffect(() => {
    if (!eventData) mutate();
  }, [eventData, mutate]);
  // console.log(event_ask_pin, event_access_key);
  let galleryPathname = useMemo(() => {
    let rootpath = pathname.replace("/", "");
    return `${rootpath}/gallery/${event_access_key}/${event_ask_pin ? 0 : 1}/${
      event_access_level === 2 ? 0 : 1
    }/${event_id}`;
  }, []);

  const [month, date2] = useMemo(() => {
    if (date) {
      let dateSplit = date.split("/");
      let eventDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "2-digit",
      };
      eventDate = new Date(eventDate).toLocaleDateString("en-US", options);
      console.log(eventDate);
      return eventDate.split(", ")[1].split(" ");
    }
  }, []);

  return (
    // <Link to={`/event/gallery?event_id=${id}`}>
    <Link
      to={{
        pathname: galleryPathname,
        state: {
          event_ask_pin: event_ask_pin,
          access_key: event_access_key,
        },
      }}
    >
      {gridStyle === "small" ? (
        <Card className="public-event-card  text-center">
          <div className="info-icons">
            {event_ask_pin ? (
              <div className="info-icon" style={{ zIndex: "10" }}>
                <i className="mdi mdi-lock " />
              </div>
            ) : (
              event_access_level === 1 && (
                <div className="info-icon" style={{ zIndex: "10" }}>
                  <i className="mdi mdi-face-recognition " />
                </div>
              )
            )}
          </div>

          <Image
            containerProps={{
              style: {
                borderRadius: "10px",
                boxSizing: "border-box",
                display: "inline-block",
                position: "relative",
                height: "160px",
                width: "100%",
                border: "none",
              },
            }}
            src={CFImageUrl(cover_image_info?.path_dict?.thumbnail_url, 640)}
            // className="img-fluid"
            style={{
              // borderRadius: "5px",
              objectFit: "cover",
              height: "160px",
              width: "100%",
              objectPosition: "100% 20%",
              border: "none",
            }}
          />

          <CardBody className="px-3 py-4 d-flex gap-4">
            <div className="d-flex flex-column ">
              <div className="month">{month}</div>
              <div className="date">{date2}</div>
            </div>
            <div className="d-flex flex-column align-items-start">
              <div className="card-title mb-0 ">{name}</div>
              {description && <div className="card-text">{description}</div>}
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <Row className="g-0 align-items-center">
            <Col md={3} style={{ minHeight: "100px" }}>
              <Image
                containerProps={{
                  style: {
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    display: "inline-block",
                    position: "relative",
                    height: "180px",
                    width: "100%",
                  },
                }}
                src={CFImageUrl(
                  eventData?.data?.cover_image_info?.path_dict?.thumbnail_url,
                  640
                )}
                // className="img-fluid"
                style={{
                  borderRadius: "5px",
                  objectFit: "cover",
                  height: "180px",
                  width: "100%",
                  objectPosition: "100% 20%",
                }}
              />
            </Col>

            <Col md={9}>
              <CardBody className="py-0">
                <div className="card-title mb-0">{name}</div>
                {description && <p className="card-text">{description}</p>}
              </CardBody>
            </Col>
          </Row>
        </Card>
      )}
    </Link>
  );
};

PublicEventCard.defaultProps = {
  gridStyle: "small",
};
export default withRouter(PublicEventCard);
