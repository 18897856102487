import LinkedInPostPreview from "./LinkedinPostPreview";
import { Container, Row, Col, Spinner } from "reactstrap";
import LinkedinSigninModal from "./LinkedinSignin";
import { useState, useEffect } from "react";
import { getLinkedinSigninUrl } from "helpers/jiraya_helper";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import CoverTheme from "components/Events/Client/CoverThemes/factory";
import LinkedInPost from "./LinkedInPost";
import useSWR from "swr";
import { GET_POSTERS_LIST } from "helpers/jiraya_url";
import { getPostersList } from "helpers/jiraya_helper";
import { GET_LINKEDIN_PROFILE } from "helpers/jiraya_url";
import { getLinkedinProfile } from "helpers/jiraya_helper";
import Error from "components/Error";
import { postLinkedinPost } from "helpers/jiraya_helper";
import { patchLinkedinPosterInfo } from "helpers/jiraya_helper";

const ListAdvocacyPosterPage = ({ eventId }) => {
  const [siginUrl, setSiginUrl] = useState("");
  const [linkedinToken, setLinkedInToken] = useState();
  const [openSignin, setOpenSignin] = useState(false);

  const is_local = window.location.hostname === "localhost";
  const handleOpenLinkedinSignin = async () => {
    try {
      const response = await getLinkedinSigninUrl({
        params: { state: encodeURI(window.location.href), is_local },
      });
      if (response.ok) {
        setSiginUrl(response.data.auth_url);
        setOpenSignin(true);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Please try again later.");
    }
  };
  useEffect(() => {
    const f = async () => {
      //  get linkedin in token from localstorage
      let linkedinToken = localStorage.getItem("linkedinToken");
      // let linkedinToken;
      // get token from url params
      if (!linkedinToken) {
        const urlParams = new URLSearchParams(window.location.search);
        const sub_id = urlParams.get("sub_id");
        const sub_uuid = urlParams.get("sub_uuid");
        if (sub_id && sub_uuid) {
          linkedinToken = { sub_id, sub_uuid };
          localStorage.setItem("linkedinToken", JSON.stringify(linkedinToken));
          const redirectUrl = new URL(window.location.href);
          redirectUrl.searchParams.delete("sub_id");
          redirectUrl.searchParams.delete("sub_uuid");
          redirectUrl.searchParams.delete("name");
          redirectUrl.searchParams.delete("email_id");

          window.location.href = redirectUrl.toString();
        }
      }

      if (!linkedinToken) {
        handleOpenLinkedinSignin();
      } else {
        setLinkedInToken(JSON.parse(linkedinToken));
        setOpenSignin(false);
      }
    };
    f();
  }, []);

  const [generatingPosters, setGeneratingPosters] = useState(true);
  const {
    data: listPosterData,
    error: listPosterError,
    mutate: listPosterMutate,
  } = useSWR(
    linkedinToken && [
      GET_POSTERS_LIST,
      linkedinToken.sub_id,
      linkedinToken.sub_uuid,
    ],
    () =>
      getPostersList({
        params: {
          event_id: eventId,
          linkedin_sub_id: linkedinToken.sub_id,
          linkedin_sub_uuid: linkedinToken.sub_uuid,
        },
      })
  );

  useEffect(() => {
    if (listPosterData || listPosterError) {
      setGeneratingPosters(false);
    }
  }, [listPosterData, listPosterError]);

  const { data: linkedinProfileData, error: linkedinProfileError } = useSWR(
    linkedinToken && [
      GET_LINKEDIN_PROFILE,
      linkedinToken.sub_id,
      linkedinToken.sub_uuid,
    ],
    () =>
      getLinkedinProfile({
        params: {
          event_id: eventId,
          linkedin_sub_id: linkedinToken.sub_id,
          linkedin_sub_uuid: linkedinToken.sub_uuid,
        },
      })
  );

  const handlePost = async ({ id, caption, image_url }) => {
    try {
      const response = await postLinkedinPost({
        advocacy_poster_id: id,
        caption,
        linkedin_sub_id: linkedinToken.sub_id,
        linkedin_sub_uuid: linkedinToken.sub_uuid,
        image_url,
        event_id: eventId,
      });
      if (response.ok) {
        listPosterMutate();
      } else {
        toast.error(response.message);
      }
      return response;
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Please try again later.");
    }
  };

  const handleOnLogout = () => {
    localStorage.removeItem("linkedinToken");
    setLinkedInToken();
    handleOpenLinkedinSignin();
  };

  useEffect(() => {
    if (linkedinProfileData && !linkedinProfileData?.ok) {
      localStorage.removeItem("linkedinToken");
      setLinkedInToken();
      handleOpenLinkedinSignin();
    }
  }, [linkedinProfileData]);

  const handleOnUpdateLinkedinInfo = async (form) => {
    try {
      setGeneratingPosters(true);
      const response = await patchLinkedinPosterInfo(form);
      if (response.ok) {
        listPosterMutate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const LogoutButton = () => (
    <div className="d-flex justify-content-center mt-4 mb-4">
      <button
        onClick={handleOnLogout}
        className="w-100 btn btn-sm btn-rounded-2 btn-soft-primary mx-4"
      >
        Logout
      </button>
    </div>
  );

  if (listPosterData && !listPosterData.ok) {
    return (
      <div className="text-warning d-flex justify-content-center align-items-center flex-column mt-4">
        <i className=" mdi mdi-alert me-2 fs-4" />
        <h3 className="text-warning">Oops !!</h3>
        <div className="text-warning">{listPosterData.message}</div>
        <div className="w-100">
          <LogoutButton />
        </div>
      </div>
    );
  }
  if (listPosterData && listPosterData.ok && listPosterData.data.length === 0) {
    return (
      <div className="text-warning d-flex justify-content-center align-items-center flex-column mt-4">
        <h3 className="text-warning">Oops !!</h3>
        <div className="text-warning">No Posters Found</div>
        <div className="w-100">
          <LogoutButton />
        </div>
      </div>
    );
  }
  if (linkedinProfileError || listPosterError) {
    return <Error className="mt-4" />;
  }

  if (linkedinToken && generatingPosters) {
    return (
      <div
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        style={{ height: "200px" }}
      >
        <Spinner size={"xl"} />
        <div className="mt-2">Generating Linkedin Posters</div>
      </div>
    );
  }

  if (!linkedinToken && !siginUrl)
    return (
      <>
        <Loader className="h-100 mt-5" />
      </>
    );

  return (
    <Container fluid>
      <LinkedinSigninModal siginUrl={siginUrl} isOpen={openSignin} />

      {linkedinToken && (
        <>
          <Row className="justify-content-center">
            <Col md={6} className="px-4 py-2 mt-4">
              <h5>Share Post</h5>
              <div>
                Share Post on your LinkedIn Profile and Tell the world about the
                exciting event
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={4}>
              <div className="mt-4">
                {listPosterData?.data.map((item, index) => (
                  <>
                    <LinkedInPost
                      key={index}
                      id={item.id}
                      imageSrc={item.image_url}
                      caption={item.caption}
                      linkedinProfileData={linkedinProfileData?.data}
                      handlePost={handlePost}
                      isPosted={item.is_posted}
                      handleOnUpdateLinkedinInfo={handleOnUpdateLinkedinInfo}
                    />
                    <div className="divider mt-4 px-2" />
                  </>
                ))}
              </div>
            </Col>
          </Row>
          {linkedinToken && <LogoutButton />}
        </>
      )}
    </Container>
  );
};

export default ListAdvocacyPosterPage;
