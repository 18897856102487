import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import LinkedInPostPreview from "./LinkedinPostPreview";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { Field, Form, Formik } from "formik";

const EditCaptionModal = ({
  id,
  isOpen,
  toggle,
  imageSrc,
  initialText,
  linkedinProfileData,
  handleOnUpdateLinkedinInfo,
}) => {
  const handleCancel = () => {
    toggle(); // Close the modal
  };
  const [file, setFile] = useState();

  const handleSubmit = (values) => {
    const form = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      form.append(key, value);
    });
    if (file) {
      form.append("file", file);
    }
    form.append("linkedin_sub_uuid", linkedinProfileData.sub_uuid);
    form.append("linkedin_sub_id", linkedinProfileData.sub_id);
    toggle();
    handleOnUpdateLinkedinInfo(form);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="spotlight">
      <ModalBody>
        {!linkedinProfileData ? (
          <>
            <Spinner />
            <div>Loading...</div>
          </>
        ) : (
          <>
            {/* <LinkedInPostPreview
              id={id}
              size={"sm"}
              imageSrc={imageSrc}
              caption={text}
              linkedinProfileData={linkedinProfileData}
              handlePost={handlePost}
            /> */}
            <Formik
              initialValues={{
                caption: initialText,
                name: linkedinProfileData.name,
                headline: linkedinProfileData.headline,
              }}
              onSubmit={handleSubmit}
            >
              <Form>
                <FormGroup>
                  <Label>Name</Label>
                  <Field as={Input} type="text" name="name" />
                </FormGroup>
                <FormGroup className="mt-2">
                  <Label>Headline</Label>
                  <Field as={Input} type="text" name="headline" />
                </FormGroup>

                <FormGroup className="mt-3">
                  <Label>Poster Photo</Label>
                  <br />
                  {/* <Field as={Input} type="file" name="file" /> */}
                  <input
                    id="file"
                    name="file"
                    type="file"
                    onChange={(event) => {
                      // console.log(event.currentTarget.files[0]);
                      setFile(event.currentTarget.files[0]);
                    }}
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <Label>Caption</Label>
                  <Field
                    as={Input}
                    name="caption"
                    type="textarea"
                    placeholder="Post Caption"
                  />
                </FormGroup>
                <div className="d-flex justify-content-end gap-2 mt-4">
                  <button
                    className="btn btn-outline-primary btn-rounded-2 "
                    onClick={handleCancel}
                    type="button"
                  >
                    Cancel
                  </button>

                  <div className="linkedin-post-preview">
                    <button
                      className="btn btn-primary btn-rounded-2 "
                      type="submit"
                    >
                      {/* <i className="me-2 fs-5 align-middle" /> */}
                      Update
                    </button>

                    {/* <Button className="linkedin-post-btn" type="submit">
                      <i className="mdi mdi-linkedin me-2 fs-5 align-middle" />
                      Update
                    </Button> */}
                  </div>
                </div>
              </Form>
            </Formik>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default EditCaptionModal;
