import Loader from "components/Loader";
import { postLinkedinAuthcode } from "helpers/jiraya_helper";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const LinkedinAuthRedirect = () => {
  const handleOnLoginSuccess = (token) => {
    // store the linkedin token in localstorage
    console.log("storing linkedin in token to local");
    localStorage.setItem("linkedinToken", JSON.stringify(token));
  };

  // get code and state from linkedin redirect url

  useEffect(() => {
    const f = async () => {
      const is_local = window.location.hostname === "localhost";
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");
      try {
        const response = await postLinkedinAuthcode({ code, is_local });
        if (response.ok) {
          const tokenData = response.data;
          handleOnLoginSuccess(response.data);
          const redirectUrl = new URL(decodeURI(state));
          Object.entries(tokenData).forEach(([key, value]) => {
            redirectUrl.searchParams.append(key, value);
          });
          window.location.href = redirectUrl.toString();
        }
      } catch (err) {
        toast.error(
          "Something went wrong while connecting your LinkedIn account"
        );
        setTimeout(() => {
          window.location.href = decodeURI(state);
        }, 2000);
      }
    };
    f();
  }, []);

  return (
    <div className="ht-100 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center gap-2">
        <Spinner />
        <div>Connecting Your LinkedIn Account</div>
      </div>
    </div>
  );
};

export default LinkedinAuthRedirect;
