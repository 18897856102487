import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const LinkedInSignInModal = ({
  siginUrl,
  isOpen,
  toggle,
  handleOnLoginSuccess,
}) => {
  const handleLinkedInSignIn = () => {
    // Replace with LinkedIn OAuth URL or relevant handler
    window.location.href = siginUrl;
  };

  // use the code from the url to get the access token
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) {
      // Send the code to the server to exchange for an access token
      fetch("https://api.example.com/linkedin/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response, e.g., store the access token
          console.log(data);
          handleOnLoginSuccess(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  return (
    <div>
      {/* Modal */}
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Create Advocacy Posters</ModalHeader>
        <ModalBody>
          <p className="text-center">Use your LinkedIn account to sign in</p>
          <div className="d-flex justify-content-center">
            <Button
              disabled={!siginUrl}
              onClick={handleLinkedInSignIn}
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#0077B5",
                borderColor: "#0077B5",
                color: "white",
                fontWeight: "400",
                padding: "3px 10px",
                fontSize: "13px",
              }}
            >
              <i
                className="mdi mdi-linkedin me-2"
                style={{ fontSize: "1rem", color: "white" }}
              ></i>
              Sign in with LinkedIn
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LinkedInSignInModal;
