import { useMemo } from "react";
import { GALLERY_ACCESS_LEVEL } from "./enums";

const useFeatureRestriction = ({ event, eventKeyType }) => {
  const features = useMemo(() => {
    let allowPeopleTab = false;
    switch (event?.creator_user_id) {
      case "7xaroBXiDETMXmHr3J3i3XU8mU72":
      case "O44jsSeDdtXVOhE1fFjTM9KiQbI3":
      case "prwBVNoJLjdR41uUrmnvHLIxm5I2": //fotomedrive@gmail.com
        // case "eUKULIgSSCVkTWTmC8DstZ2uLWI2": //vivek.patil@mmactiv.com
        if (eventKeyType === GALLERY_ACCESS_LEVEL.FULL) allowPeopleTab = true;
        break;
    }
    return { allowPeopleTab };
  }, [event]);

  return features;
};

export default useFeatureRestriction;
