import successAnimation from "assets/images/animations/success_animation.json";
import warningAnimation from "assets/images/animations/warning_animation.json";
import couponPopper from "assets/images/animations/coupon-poppers.json";
import rainAnimation from "assets/images/animations/rain_animation.json";

const SuccessPopup = ({ heading, children, popper }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      {popper && (
        <>
          <lottie-player
            src={JSON.stringify(couponPopper)}
            background="transparent"
            speed="1"
            class="coupon-popper left"
            loop
            autoplay
          ></lottie-player>
          <lottie-player
            src={JSON.stringify(couponPopper)}
            background="transparent"
            speed="1"
            class="coupon-popper right"
            loop
            autoplay
          ></lottie-player>
        </>
      )}

      <lottie-player
        src={JSON.stringify(successAnimation)}
        background="transparent"
        speed="1"
        style={{ width: 150 }}
        loop
        autoplay
      ></lottie-player>
      <h4 style={{ textTransform: "capitalize" }}>
        {/* <i className="mdi mdi-check-circle text-success me-2" /> */}
        {heading}
      </h4>
      {children}
    </div>
  );
};

const WarningPopup = ({ heading, children, rain }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      {rain && (
        <>
          <lottie-player
            src={JSON.stringify(rainAnimation)}
            background="transparent"
            speed="1"
            class="rain-animation left"
            loop
            autoplay
          ></lottie-player>
          <lottie-player
            src={JSON.stringify(rainAnimation)}
            background="transparent"
            speed="1"
            class="rain-animation right"
            loop
            autoplay
          ></lottie-player>
        </>
      )}
      <lottie-player
        src={JSON.stringify(warningAnimation)}
        background="transparent"
        speed="1"
        style={{ width: 150 }}
        loop
        autoplay
      ></lottie-player>
      <h4 style={{ textTransform: "capitalize" }}>
        {/* <i className="mdi mdi-check-circle text-success me-2" /> */}
        {heading}
      </h4>
      {children}
    </div>
  );
};

export { SuccessPopup, WarningPopup };
