import React, { useMemo } from "react";

import {
  socialMediaData,
  handleOpenLink,
} from "../Events/Client/Branding/utils";

import { Row, Col } from "reactstrap";

const PublicGalleryFooter = ({ branding }) => {
  const socialMediaFooter = useMemo(() => {
    let smF = [];
    if (branding) {
      socialMediaData.forEach((sm) => {
        if (branding[sm.apiName]) {
          smF.push(
            <div className="public-event-footer-link-title">
              <div onClick={() => handleOpenLink(sm.apiName)}>
                <img src={sm.iconWhiteFill} height={20} className="me-1" />
                {sm.name}
              </div>
            </div>
          );
        }
      });
    }
    return smF;
  }, [branding]);
  return (
    <>
      <div>
        <Row className="p-3 gx-0 mb-4 ">
          <Col md={6}>
            <div className="public-gallery-footer text-center d-flex flex-column">
              <div className="text-center">
                <h2>{branding ? branding?.name : "Event Gallery"}</h2>
              </div>
              <div className="logo-img">
                {branding?.img_url ? (
                  <img
                    className="img-fluid logo "
                    src={branding?.img_url}
                    alt="brand_logo"
                  />
                ) : null}
              </div>

              {/* <button
                style={{ cursor: "pointer" }}
                className="btn btn-primary d-none d-md-block"
                onClick={() => {
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}
              >
                Top <i className="mdi mdi-arrow-up fs-5 fs-md-3"></i>
              </button> */}
            </div>
          </Col>
          <Col md={5} className="px-4 px-md-0">
            <div className="public-event-footer-link">
              <Row className="gy-4">
                {socialMediaFooter.length > 0 && (
                  <Col xs={12} md={5}>
                    <div className="d-flex flex-column gap-2 align-items-start">
                      <div className="public-event-footer-link-header">
                        FOLLOW US
                      </div>
                      {socialMediaFooter}
                    </div>
                  </Col>
                )}
                {(branding?.phone_number || branding?.email_id) && (
                  <Col xs={12} md={6}>
                    <div className="d-flex flex-column gap-2 align-items-start">
                      <div className="public-event-footer-link-header">
                        Contact Us
                      </div>
                      {branding?.contact_number && (
                        <div className="public-event-footer-link-title">
                          <a href={`tel:${branding?.contact_number}`}>
                            <i className="mdi mdi-cellphone text-center contact-icon" />{" "}
                            {branding?.contact_number}
                          </a>
                        </div>
                      )}
                      {branding?.email_id && (
                        <div className="public-event-footer-link-title">
                          <a href={`mailto:${branding?.email_id}`}>
                            <i className="mdi mdi-email text-center contact-icon me-1" />
                            {branding?.email_id}
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PublicGalleryFooter;
