import { useEffect, useState } from "react";
import { FormGroup, Input, Col, Row, Label } from "reactstrap";

const machine_data = {
  "Excavator BU": {
    "Mini Excavator": ["SY35 pro", "SY60 C"],
    "Small Excavator": ["SY80 pro", "SY150 pro"],
    "Medium Excavator": ["SY210 SPARC +", "SY215c-9 lc X", "SY225 GENe +"],
    "Motor Grader": ["STG140 BS5/STG170BS5"],
  },
  "Piling BU": {
    "Piling Rig": ["SR235DW", "SR140", "SH500/SH700", "SR285R/SR360R"],
  },
  "Asphalt BU": {
    "Batching Plant": ["SABP180"],
    Paver: ["SAP100C10 BSV"],
    "Milling Machine": ["SCM\xa0 2000"],
    Compactor: ["SSR20"],
  },
  "Mining Division": {
    "Large Excavator": ["SY680"],
    "Mining Truck": ["SKT 105E"],
  },
  "Crane BU": {
    Articulated: ["SPA 20 meters"],
    Telescopic: ["SPT 26 / 32 meters"],
    "Scissor lift": ["SPS 1212"],
    "Truck Crane": ["STC600T5P (60 T Hybrid)"],
    "All Terrain Crane": ["SAC3000"],
    "Crawler Crane": ["SCS1500A Luffing"],
    "Rough Terrain Crane": ["SRC400CR"],
  },
  "Port BU": {
    "Reach stacker": ["SRSC45E3 Electric"],
    "E-ITV Terminal Tractor": ["SM4256T0BEV"],
  },
};

const SanyForm = ({ handleChange, handleRegisterName, additionalValues }) => {
  const [machineList, setMachineList] = useState([]);
  const [machineData, setMachineData] = useState([
    {
      Component: MachineField,
      props: { category: "", subcategory: "", modelcode: "", qty: 0 },
    },
  ]);
  useEffect(() => {
    handleRegisterName("machine_quantity_list", false, "");
    handleRegisterName("city", false, "");
    handleRegisterName("state", false, "");
  }, []);

  const handleOnChangeFormValue = (machineData) => {
    const machineList = [];
    machineData.forEach((data) => {
      machineList.push(data.props);
    });
    handleChange("machine_quantity_list", machineList);
  };
  const handleOnChange = (idx, name, value) => {
    machineData[idx].props[name] = value;
    setMachineData([...machineData]);
    handleOnChangeFormValue([...machineData]);
  };

  const handleOnAddMachineGroup = () => {
    const newData = [
      ...machineData,
      {
        Component: MachineField,
        props: { category: "", subcategory: "", modelcode: "", qty: 0 },
      },
    ];
    setMachineData(newData);
    handleOnChangeFormValue(newData);
  };
  const handleOnRemoveMachineGroup = (idx) => {
    machineData.splice(idx, 1);
    setMachineData([...machineData]);
  };

  return (
    <div className="">
      <FormGroup className="mb-3">
        <Label>City</Label>
        <Input
          value={additionalValues["city"]?.value || ""}
          name={"city"}
          placeholder={"City"}
          type="text"
          onChange={(e) => handleChange("city", e.target.value)}
          className={`form-control`}
          id={"city"}
          invalid={additionalValues["city"]?.isError}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label>State </Label>
        <Input
          value={additionalValues["state"]?.value || ""}
          name={"state"}
          placeholder={"State"}
          type="text"
          onChange={(e) => handleChange("state", e.target.value)}
          // errorMessage={errorMessage}
          className={`form-control`}
          // validate={{ required: { value: errorMessage } }}
          id={"state"}
          invalid={additionalValues["state"]?.isError}
        />
      </FormGroup>
      {machineData.map((data, idx) => {
        const { Component, props } = data;
        return (
          <Component
            idx={idx}
            key={idx}
            {...props}
            handleOnChange={handleOnChange}
            handleOnRemoveMachineGroup={handleOnRemoveMachineGroup}
          />
        );
      })}
      <div className="d-flex justify-content-end mb-2 mt-3">
        <button
          onClick={handleOnAddMachineGroup}
          className="btn btn-gallery-outline-primary px-4"
        >
          <i className="mdi mdi-plus-circle-outline" /> Add
        </button>
      </div>
    </div>
  );
};

const MachineField = ({ idx, handleOnChange, handleOnRemoveMachineGroup }) => {
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [modelList, setModelList] = useState([]);

  const handleOnChangeValue = (name, value) => {
    console.log(name);
    if (name === "category") {
      setSubcategoryList(machine_data[value]);
    } else if (name == "subcategory") {
      setModelList(subcategoryList[value]);
    }
    handleOnChange(idx, name, value);
  };

  return (
    <div className="border border-primary border-opacity-50 rounded py-4 px-2 mt-4 position-relative">
      <div
        className="position-absolute top-0 end-0 me-1"
        onClick={() => handleOnRemoveMachineGroup(idx)}
      >
        <i className="mdi mdi-close-circle fs-2" />
      </div>
      <FormGroup className="mb-3 ">
        Category
        <Input
          type="select"
          name={"category"}
          id={"category"}
          // invalid={additionalValues[name]?.isError}
          onChange={(e) => handleOnChangeValue("category", e.target.value)}
          // className={``}
        >
          <option disabled selected value>
            select category
          </option>
          {Object.keys(machine_data).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Input>
        {/* <FormFeedback>{errorMessage}</FormFeedback> */}
      </FormGroup>
      <FormGroup className="mb-3">
        Sub-Category
        <Input
          type="select"
          name={"subcategory"}
          id={"subcategory"}
          // invalid={additionalValues[name]?.isError}
          onChange={(e) => handleOnChangeValue("subcategory", e.target.value)}
        >
          <option disabled selected value>
            select subcategory
          </option>
          {Object.keys(subcategoryList).map((subcategory) => (
            <option key={subcategory} value={subcategory}>
              {subcategory}
            </option>
          ))}
        </Input>
      </FormGroup>
      <Row>
        <Col>
          <FormGroup className="mb-3">
            Model
            <Input
              type="select"
              name={"modelcode"}
              id={"modelcode"}
              // invalid={additionalValues[name]?.isError}
              onChange={(e) => handleOnChangeValue("modelcode", e.target.value)}
            >
              <option disabled selected value>
                select model
              </option>
              {modelList.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-3">
            Quantity
            <Input
              name={"quantity"}
              placeholder={"Quantity"}
              type="number"
              onChange={(e) => handleOnChangeValue("quantity", e.target.value)}
              className={`form-control `}
              id={"quantity"}
              // invalid={additionalValues[name]?.isError}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SanyForm;
