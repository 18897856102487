import EditCaptionModal from "./EditCaption";
import LinkedInPostPreview from "./LinkedinPostPreview";
import { useState } from "react";

const LinkedInPost = ({
  id,
  imageSrc,
  caption,
  linkedinProfileData,
  handlePost,
  isPosted,
  handleOnUpdateLinkedinInfo,
}) => {
  const [postCaption, setPostCaption] = useState(caption);
  const [isOpenEditCaption, setIsOpenEditCaption] = useState(false);
  const handleOnOpenEditCaption = () => {
    setIsOpenEditCaption(true);
  };
  const toggleEditCaption = () => {
    setIsOpenEditCaption(!isOpenEditCaption);
  };

  return (
    <>
      <LinkedInPostPreview
        id={id}
        size={"big"}
        imageSrc={imageSrc}
        caption={postCaption}
        handleOnOpenEditCaption={handleOnOpenEditCaption}
        linkedinProfileData={linkedinProfileData}
        handlePost={handlePost}
        isPosted={isPosted}
      />
      <EditCaptionModal
        id={id}
        initialText={caption}
        imageSrc={imageSrc}
        isOpen={isOpenEditCaption}
        toggle={toggleEditCaption}
        linkedinProfileData={linkedinProfileData}
        handleOnUpdateLinkedinInfo={handleOnUpdateLinkedinInfo}
      ></EditCaptionModal>
    </>
  );
};
export default LinkedInPost;
