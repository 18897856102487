import {
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { EVENT_ORDER_BY } from "../Events/enums";
import { useState, useEffect } from "react";
import classNames from "classnames";

const EventFilter = ({
  toggle,
  setSearchQuery,
  searchQuery,
  setEventSearchQuery,
  handleSort,
}) => {
  const [hideClearSearch, setHideClearSearch] = useState(true);
  const [value, setValue] = useState();
  const handleOnChangeSearchQuery = (e) => {
    if (!e.target.value || e.target.value === "") {
      setHideClearSearch(true);
    } else {
      setHideClearSearch(false);
    }
    onChangeSearchQuery(e.target.value);
  };

  const handleClearSearchQuery = () => {
    setHideClearSearch(true);
    onChangeSearchQuery("");
    handleOnSearch("", true);
  };
  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      handleOnSearch();
    }
  };

  const onChangeSearchQuery = (text) => {
    setSearchQuery(text);
  };
  const handleOnSearch = (value, override) => {
    if (override) {
      setEventSearchQuery(value);
    } else {
      setEventSearchQuery(searchQuery);
    }
  };
  const handleOnOrderBy = (v) => {
    handleSort(v);
  };
  return (
    <Row className="d-flex justify-content-center gx-1">
      <Col md={11} xl={9}>
        <div className="mx-2 d-flex align-items-center flex-wrap">
          <h2 className=" flex-grow-1 m-0 me-2 mt-3">Live Events </h2>
          <div className="search ms-md-5 me-1 mt-3 mt-md-1">
            <div className="search-input-wrap w-100">
              <input
                onKeyDown={handleSearchOnEnter}
                className="search-input w-100 p-2"
                placeholder="event name"
                value={searchQuery}
                onChange={handleOnChangeSearchQuery}
              />
              <i
                onClick={handleClearSearchQuery}
                className={classNames("mdi mdi-close search-clear", {
                  "d-none": hideClearSearch,
                })}
              />
            </div>

            <button
              onClick={() => handleOnSearch()}
              className="btn btn-soft-primary search-btn me-1"
            >
              <i className="mdi mdi-magnify" />
            </button>
            <div className="ms-1 ms-md-2">
              {/* <small>Sort By</small> */}
              <UncontrolledDropdown direction="left">
                <DropdownToggle
                  style={{
                    width: "max-content",
                  }}
                  tag="div"
                >
                  <button className="btn btn-outline-secondary py-0 d-flex">
                    <i className="mdi mdi-sort fs-5"></i>
                    {/* <div className="d-none d-md-block">Sort By</div> */}
                    {/* <i className="mdi mdi-chevron-down d-none d-md-block" /> */}
                  </button>
                </DropdownToggle>
                <DropdownMenu className="px-1 px-md-3">
                  {/* <b>Creation Time</b> */}
                  {/* <DropdownItem
                    onClick={() =>
                      handleOnOrderBy(EVENT_ORDER_BY.EVENT_CREATION_TIME_ASC)
                    }
                  >
                    Old-New
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleOnOrderBy(EVENT_ORDER_BY.EVENT_CREATION_TIME_DESC)
                    }
                  >
                    New-Old
                  </DropdownItem>
                  <hr /> */}
                  <b>Event Time</b>
                  <DropdownItem
                    onClick={() =>
                      handleOnOrderBy(EVENT_ORDER_BY.EVENT_TIME_ASC)
                    }
                  >
                    Old-New
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleOnOrderBy(EVENT_ORDER_BY.EVENT_TIME_DESC)
                    }
                  >
                    New-Old
                  </DropdownItem>
                  <hr />
                  <b>Event Name</b>
                  <DropdownItem
                    onClick={() =>
                      handleOnOrderBy(EVENT_ORDER_BY.EVENT_NAME_ASC)
                    }
                  >
                    A-Z
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleOnOrderBy(EVENT_ORDER_BY.EVENT_NAME_DESC)
                    }
                  >
                    Z-A
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EventFilter;
